@import '/src/components/css/sharedStyles';

.beforeOpenLockPage {
    padding: 0 8px;

    .beforeOpenLockPageCustomFieldsContainer {
        border-radius: 8px;
        margin-bottom: 24px;
        padding-top: 8px;

        .container {
            @include container;
            @include form-group;
            @include typography;
            @include checkbox-and-icons;
            @include box;
            @include input-focus;
            @include image-upload;
        }

        .goToOpenLockPage-btn {
            @include submit-button;
        }
    }
}