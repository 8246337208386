@import '/src/components/css/sharedStyles';

.returnPage-box {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 0 8px;

    .returnPageCustomFieldsContainer {
        border-radius: 8px;
        margin-bottom: 24px;
        padding: 8px 0 0 0;
        width: 100%;

        .container {
            @include container;
            @include form-group;
            @include typography;
            @include checkbox-and-icons;
            @include box;
            @include input-focus;
            @include image-upload;
        }

        .end-booking-btn {
            @include submit-button;
        }
    }
}