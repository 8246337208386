//============================================================
//   RETURNSTYLES.SCSS, BEFOREOPENLOCK.SCSS, CARTSTEP.SCSS
//============================================================

@mixin container {
  background-color: #FFFFFF;
  padding: 14px 16px 0 16px !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    display: block;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #e2e2e2;
    padding-top: 16px !important;
  }
}

@mixin form-group {
  img {
    width: 30px;
    margin: 4px;
    padding: 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .MuiFormControlLabel-root {
    text-align: left;
  }
}

@mixin typography {
  .MuiTypography-root {
    color: black;
    text-align: left;
  }

  .centeredText {
    text-align: center;
    margin-bottom: 8px;
  }
}

@mixin checkbox-and-icons {

  .MuiSvgIcon-root,
  .MuiCheckbox-root {
    font-size: 1.5rem;
    color: 'primary.main';
    border-width: 1px !important;
  }
}

@mixin box {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: white;
  margin: 16px 0;
  padding: 8px;

  img {
    width: 15%;
    object-fit: contain;
  }

  .productHeading {
    width: 40%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .info {
    width: 33.3%;
  }
}

@mixin input-focus {
  .MuiInputBase-root {
    border-radius: 0;
  }

  .Mui-focused {
    color: #474646;
  }
}

@mixin image-upload {
  .img-upload-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;

    .img-upload-container__left-column {
      display: flex;
      justify-content: center;
      grid-column: 1 / span 7;

      .upload-btn,
      .img-upload-container__button {
        width: fit-content;
        min-width: 185px;
        width: auto;
        max-width: 190px;

        .takePictureBtn {
          cursor: pointer;
          background-color: #728a6c;
          border-radius: 23px;
          border: none;
          padding: 4px 12px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          svg {
            font-size: 25px;
          }
        }
      }
    }

    .image-preview {
      grid-column: 9 / span 4;
      min-width: 95px;
      width: 100%;
      max-width: 110px;
      height: auto;
      max-height: 89px;
      object-fit: contain;
      object-position: center;
      overflow: hidden;
      border-radius: 15px;
    }

    div {
      grid-column: 9 / span 4;
      max-width: 110px;

      img {
        min-width: 95px;
        width: 100%;
        max-width: 110px;
        height: auto;
        max-height: 89px;
        object-fit: contain;
        object-position: center;
        overflow: hidden;
        border-radius: 15px;
      }
    }
  }
}

@mixin submit-button {
  button {
    border-radius: 0 0 8px 8px;
    width: 100%;
    text-transform: capitalize;
    color: 'primary.main';
    padding: 8px 16px;
  }

  .Mui-disabled {
    background-color: #e6e4e4;
  }
}