.text-info {
    text-align: left;
    display: flex;
    align-items: center;

    &>span>p {
        margin: 0;
    }
}

.linkText {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px !important;
}

.dialog__text-info {
    padding: 1rem;
}