.img-upload-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;

    img {
        width: 100px;
        object-fit: cover;
        object-position: right;
        border-radius: 15px;
    }

    .img-upload-container__button {
        border-radius: 30px;
    }
}

.img-upload-container__left-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100px;

    >div {
        text-align: left;
    }
}