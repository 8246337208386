.mui-calendar-stack-container {
    padding: 16px !important;

    .pickUp-box {
        margin-right: 4px;

        .MuiButtonGroup-root {
            width: 100% !important;
            height: 80px;

            .pickup-date {
                border-radius: 8px 8px 0px 0px;
                justify-content: flex-start;
                border: 1px solid;
                color: #393939;
                height: 40px;
            }
        }
    }

    .return-box {
        margin-left: 4px;

        .MuiButtonGroup-root {
            width: 100% !important;

            .return-date {
                border-radius: 8px 8px 0px 0px;
                justify-content: flex-start;
                border: 1px solid;
                color: #393939;
                height: 40px;
            }
        }
    }
}

.collapse-box {
    .MuiDateRangeCalendar-monthContainer {
        margin-bottom: 20px;
    }

    .MuiDateRangeCalendar-monthContainer {
        margin-bottom: 20px;
    }

    .box-container {
        width: 360px;
        margin: 0 auto;
        position: absolute;
        z-index: 10000;
        right: 0;
        left: 0;
        margin-top: 10px;
        transform: scale(1.1);

        .dateRangePicker-box {
            border: 1px solid #979797;
            border-radius: 8px;

            .MuiPickersDay-today {
                background-color: transparent;
                border-radius: 6px !important;
                border: none;
                color: #415536;
                font-weight: 600;
            }

            .MuiDateRangePickerDay-rangeIntervalDayHighlight {
                background-color: rgba(55, 87, 35, 0.3);
                border-radius: 0;
            }

            .MuiDateRangePickerDay-day {
                &:hover {
                    border-radius: 6px !important;
                }
            }

            // Interval start date
            .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
                background-color: transparent;

                .Mui-selected {
                    background-color: rgba(55, 87, 35, 0.5);
                    color: #393939;
                    border-radius: 6px 0 0 6px !important;
                }
            }

            .MuiDateRangePickerDay-rangeIntervalPreview {
                border-radius: 6px;
            }

            // Interval end date
            .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
                background-color: transparent;

                .Mui-selected {
                    background-color: rgba(55, 87, 35, 0.5);
                    color: #393939;
                    border-radius: 0px 6px 6px 0px;
                }
            }

            .Mui-selected {
                background-color: rgba(55, 87, 35, 0.5);
                color: #393939;
                border-radius: 6px 0 0 6px;
            }

            .MuiDateRangeCalendar-monthContainer {
                width: 100%;
            }
        }
    }
}

.badge-available,
.badge-full,
.badge-partial {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-left: -16px;
    margin-top: 8px;
}