@import '/src/components/css/sharedStyles';

.react-tel-input .special-label {
    display: block; // If not set to block special label will not be displayed
    left: 12px;
}

.react-tel-input .flag-dropdown {
    border: none;
    background: none;
    padding: 0 0 0 14px;

    &.open {
        background: none;

        .selected-flag.open {
            background: none;
        }
    }
}

.input-focused input.form-control:focus {
    border: 2px solid #004E31;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

.react-tel-input .selected-flag {
    padding: 0;

    &:hover,
    &:focus {
        background: none;
    }
}

.input-focused-error.react-tel-input input.form-control {
    border: 1px solid rgb(211, 47, 47);

    &:focus {
        border: 2px solid rgb(211, 47, 47);
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
}

.customerPaymentDetailsContainer {
    border-radius: 8px;
    margin: 16px 0 24px 0 !important;
    padding-top: 16px;

    .customerDetailsContainer {
        display: flex;
        flex-direction: column;
        padding: 14px 0 0 0 !important;

        &::after {
            content: "";
            display: block;
            width: 100%;
            margin: 0 auto;
            border-bottom: 1px solid #e2e2e2;
            padding-top: 16px !important;
        }

        .customerDetails {
            background-color: #FFFFFF;
            padding: 8px 0 4px 0 !important;
            margin: 0 !important;
            display: flex;
            flex-direction: column;
        }
    }

    .container {
        @include form-group;
        @include typography;
        @include checkbox-and-icons;
        @include box;
        @include input-focus;
        @include image-upload;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        padding: 4px 0 0 0 !important;

        .img-upload-container {
            padding: 8px 0 16px 0 !important;
        }
    }
}